<template>
    <el-dialog
        title="Entity Details"
        width="30%"
        :visible="true"
        @close="$emit('close')"
    >
        <el-form>
            <el-form-item
                :label="showNameInput ? 'Entity Name' : 'Select Entity'"
                :error="getValidationMessage('name') || getValidationMessage('farm_id')"
            >
                <el-input
                    v-if="showNameInput"
                    ref="nameInput"
                    v-model="entity.name"
                />
                <el-select
                    v-else
                    :value="entity.farm_id"
                    placeholder="Start typing to search..."
                    remote
                    filterable
                    :remote-method="loadFarms"
                    :loading="areFarmsLoading"
                    @change="changeFarm"
                >
                    <el-option-group key="farms">
                        <el-option
                            v-for="item in farmOptions"
                            :key="item.value"
                            :label="item.label"
                            :disabled="item.disabled"
                            :value="item.value"
                        />
                    </el-option-group>

                    <el-option-group key="create-new">
                        <el-option
                            label="Create New Entity"
                            value="createNewEntity"
                        />
                    </el-option-group>
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="productName"
                label="Figured Product Plan"
                class="product-plan"
            >
                <el-input
                    :value="productName"
                    class="well-disabled"
                    :readonly="true"
                />
            </el-form-item>
            <el-form-item
                label="Entity Type"
                :error="getValidationMessage('type')"
            >
                <el-select
                    v-model="entity.type"
                    :disabled="!value"
                    placeholder="Select Type..."
                >
                    <el-option
                        v-for="entityType in entityTypes"
                        :key="entityType.key"
                        :value="entityType.key"
                        :label="entityType.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                label="Entity Purpose"
                :error="getValidationMessage('purposes')"
            >
                <el-select
                    v-model="entity.purposes"
                    multiple
                    placeholder="Select purposes..."
                >
                    <el-option
                        v-for="purpose in entityPurposes"
                        :key="purpose"
                        :value="purpose"
                    />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <span
                class="dialog-footer"
            >
                <el-button @click="$emit('close')">Cancel</el-button>
                <el-button
                    type="success"
                    :loading="submitLoading"
                    @click="saveEntity"
                >Submit</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

export default {
    name: "BaseEntityForm",
    props: {
        value: {type: Object, required: true}, // the selected entity
        farms: {type: Array, required: true, default: () => []},
        entityTypes: {type: Array, required: true},
        entityPurposes: {type: Array, required: true},
        validationMessages: {type: Object, required: false, default: () => ({})},
        areFarmsLoading: {type: Boolean, required: true},
        submitLoading: {type: Boolean, required: true},
    },
    data() {
        return {
            query: null,
            entity: Object.assign({
                id: null,
                name: "",
                farm_id: null,
                farm: null,
                type: null,
                purposes: []
            }, cloneDeep(this.value)),
            showNameInput: this.value.id && !this.value.farm_id
        }
    },
    computed: {
        farmOptions() {
            const farmOptions = [];

            this.farms.forEach(farm => {
                if (farm.id === this.entity.farm_id) {
                    return;
                }

                farmOptions.push({
                    label: farm.name,
                    value: farm.id,
                    //disabled if a farm already has an entity, but not if this is the currently selected farm
                    disabled: !!farm.entity
                })
            });

            if (this.entity.farm && !this.query) {
                farmOptions.push({
                    label: this.entity.farm.name,
                    value: this.entity.farm.id,
                });
            }

            return farmOptions;
        },
        selectedEntityName() {
            return get(this.entity, "name", "Select Entity...");
        },
        productName() {
            return get(this.entity, "farm.product.name", "");
        },
    },
    methods: {
        changeFarm(value) {
            if (value === "createNewEntity") {
                this.entity.farm_id = null;
                this.entity.farm = null;
                this.entity.name = this.query;
                this.showNameInput = true;
                this.$nextTick(() => {
                    this.$refs["nameInput"].focus();
                });
                return;
            }

            const farm = this.farms.find(farm => farm.id === value);
            this.query = "";
            this.entity.name = farm.name;
            this.entity.farm_id = farm.id;
            this.entity.farm = farm;
            this.entity.type = farm.entity_type;
            this.$emit("changed-entity");
        },
        getValidationMessage(path) {
            return get(this.validationMessages, [path, 0]);
        },
        loadFarms(query) {
            this.query = query;
            this.$emit("query-farms", query);
        },
        saveEntity() {
            this.$emit("save-entity", this.entity);
        },
    }
}
</script>

<style>
.well-disabled > input {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    cursor: not-allowed;
}
</style>