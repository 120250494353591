<template>
    <el-dropdown
        class="business-structure__entity__dropdown"
        @command="handleItemClicked"
    >
        <div class="business-structure__entity__dropdown__icon align-items-center flex justify-center">
            <i class="far fa-ellipsis-v" />
        </div>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item
                    v-for="(item, index) in commands"
                    :key="index"
                    :command="item.key"
                    :class="dropdownItemClass(item.key)"
                >
                    {{ item.label }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script>
export default {
    name: "BaseEntityDropdown",

    props: {
        commands: {
            type: Array,
            required: true
        }
    },

    methods: {
        handleItemClicked(command) {
            this.$emit("command", command);
        },
        dropdownItemClass(command) {
            return command === "delete" ? "business-structure__entity__dropdown__delete-option" : "";
        }
    }
}
</script>

<style lang="scss">
    .business-structure__entity__dropdown {
        display: block;
        position: absolute;
        top: 3px;
        right: -3px;

        &__icon {
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            margin-right: .5rem;

            &:hover {
                background-color: #eceff1;
            }
        }
    }

    .business-structure__entity__dropdown__delete-option {
        color: #FF5555;
    }
</style>