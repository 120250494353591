import { Notification } from "element-ui";

const defaultOptions = {
    duration: 8000,
    position: "top-right",
    showClose: true,
    onClick: null,
};
export function success(message, title = null) {
    const notification = Notification.success({
        ...defaultOptions, customClass: "element-ui-notification notification-success", message, title
    });
    return close(notification);
}
export function info(message, title= null) {
    const notification = Notification.info({
        ...defaultOptions, customClass: "element-ui-notification notification-info", message, title
    });
    return close(notification);
}
export function error(message, title = null) {
    const notification = Notification.error({
        ...defaultOptions, customClass: "element-ui-notification notification-error", message, title
    });
    return close(notification);
}
export function warning(message, title = null) {
    const notification = Notification.warning({
        ...defaultOptions, customClass: "element-ui-notification notification-warning", message, title
    });
    return close(notification);
}
export function danger(message, title = null) {
    const notification = Notification.warning({
        ...defaultOptions, customClass: "element-ui-notification notification-danger", message, title
    });
    return close(notification);
}
export function onClick(callback) {
    defaultOptions.onClick = callback;
}
export function clear() {
    Notification.closeAll();
}
export function close(notification) {
    notification.$el.onclick = () => {
        notification.close();
    };
    return notification;
}
